@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/POPPINS.ttf");
}

// $padding: 20px;

.container {
  box-sizing: border-box;
  font-family: "Poppins";
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  background-color: #011e41;

  .main_title {
    font-size: 45px;
  }

  .single_content {
    font-size: 18px;
  }

  @media only screen and (max-width: 1480px) {
    .main_title {
      font-size: 40px;
    }
  }

  @media only screen and (max-width: 1240px) {
    .main_title {
      font-size: 38px;
    }

    .single_content {
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 1170px) {
    .main_title {
      font-size: 34px;
    }

    .single_content {
      font-size: 15px;
    }
  }

  @media only screen and (max-width: 1112px) {
    .main_title {
      font-size: 30px;
    }

    .single_content {
      font-size: 15px;
    }
  }

  @media only screen and (max-width: 1020px) {
    .main_title {
      font-size: 28px;
    }

    .single_content {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 500px) {
    .main_title {
      font-size: 26px;
    }
  }

  @media only screen and (max-width: 400px) {
    .main_title {
      font-size: 24px;
    }
  }
}
