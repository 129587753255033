@font-face {
  font-family: "Poppins";
  src: url("../../../public/fonts/POPPINS.ttf");
}

.container_customers {
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    position: relative;
    .cart {
      box-sizing: border-box;
      padding: 40px 50px;
      width: 50%;
      align-self: center;

      .cart_header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-bottom: 54px;
      }

      .cart_title {
        color: #011e41;
        font-size: 45px;
        font-style: normal;
        font-weight: 700;
        line-height: 56px;

        span {
          font-family: "Poppins";
        }

        span:nth-child(3) {
          font-weight: 400;
        }

        img {
          height: 93px;
          margin-top: -16px;
          padding-left: 3%;
          position: absolute;
        }

        .title_bg {
          height: 93px;
          margin-top: -18px;
          padding-left: 1%;
        }

        .title_bg_en {
          height: 93px;
          margin-top: -18px;
          margin-left: -90px;
        }
      }

      .cart_header_logo {
        height: 40px;
        width: auto;
      }

      .cart_body {
        color: #011e41;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
      }

      .cart_footer {
        padding-top: 45px;

        button {
          border: 0.2px solid #b0b8c2;
          border-radius: 30px;
          font-family: "Poppins";
          line-height: 27px;
          padding: 0px 61px 0px 20px;
          height: 59px;
          font-size: 18px;
        }
        .first_btn {
          background: #f7f9ff;
          color: #011E41;
          font-weight: 300;
          text-align: left;
        }

        .second_btn {
          background: linear-gradient(90deg, #1c06f4 0%, #e600ff 100%);
          color: #fff;
          cursor: pointer;
          font-weight: 800;
          margin-left: -48px;
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      .img_grapper {
        align-items: center;
        display: flex;
        justify-content: space-around;
      }
    }

    .cart:before {
      content: "";
      border-right: 1px solid #e8e8e8;
      height: 68%;
      left: 50%;
      position: absolute;
      top: 16%;
    }

    .cart_insurance {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .coverage_background {
    background-image: url("../../assets/images/coverage_bg.png");
    background-size: cover;
    background-position: center;

    .cart {
      // background: linear-gradient(0deg, rgba(1, 30, 65, 0.61) 0%, rgba(5, 25, 67, 0.55) 100%);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding: 80px 50px;

      .cart_title {
        color: #fff;
        font-family: "Poppins";
        font-size: 45px;
        font-style: normal;
        font-weight: 700;
        line-height: 124.5%; /* 56.025px */
        text-align: right;
        text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.4);
        text-transform: capitalize;

        span:nth-child(3) {
          font-weight: 400;
        }
      }

      .cart_body {
        color: #fff;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        text-align: right;
        text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.4);
      }
    }
  }
}

@media only screen and (max-width: 1480px) {
  .container_customers {
    .row {
      .cart {
        padding: 60px 20px;

        .cart_header_logo {
          height: 32px;
        }
        .cart_title {
          .title_bg_en {
            height: 93px;
            margin-top: -18px;
            margin-left: -140px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .container_customers {
    .row {
      .cart {
        .cart_title {
          img {
            height: 70px;
            margin-top: -4px;
            padding-left: 4%;
          }

          .title_bg {
            margin-top: -4px;
            padding-left: 3%;
            height: 70px;
          }

          .title_bg_en {
            margin-top: -4px;
            margin-left: -166px;
            height: 70px;
          }
        }
      }
    }

    .coverage_background {
      .cart {
        .img_small {
          img {
            height: 30px;
          }
          img:nth-child(4) {
            height: 35px;
          }
        }
        .img_medium {
          img {
            height: 65px;
          }
        }
        .img_big {
          img {
            height: 70px;
          }
          img:nth-child(2) {
            height: 25px;
          }
          img:nth-child(3) {
            height: 30px;
          }
          img:nth-child(4) {
            height: 40px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1110px) {
  .container_customers {
    .row {
      .cart {
        padding: 25px 30px;
        .cart_header {
          .cart_title {
            font-size: 20px;
          }

          .cart_header_logo {
            height: 36px;
          }
        }
        .cart_title {
          .title_bg_en {
            margin-left: -90px;
          }
        }
      }

      .cart_insurance {
        .img_grapper {
          img {
            height: 54px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1056px) {
  .container_customers {
    .row {
      .cart {
        padding: 25px 24px;
      }
    }
  }
}

@media only screen and (max-width: 1025px) {
  .container_customers {
    .row {
      .cart {
        padding: 35px 15px;
        .cart_title {
          font-size: 16px;

          .title_bg_en {
            margin-top: -23px;
            margin-left: -100px;
          }
        }

        .cart_body {
          line-height: normal;
        }
      }

      .cart_insurance {
        .img_grapper {
          img {
            height: 45px;
          }
        }
      }
    }

    .coverage_background {
      .cart {
        padding: 35px;
        .cart_title {
          font-size: 16px;

          .title_bg {
            margin-top: -24px;
            padding-left: 1%;
          }
        }

        font-size: 16px;
        .img_small {
          img {
            height: 25px;
          }
          img:nth-child(4) {
            height: 35px;
          }
        }
        .img_medium {
          img {
            height: 60px;
          }
        }
        .img_big {
          img {
            height: 65px;
          }
          img:nth-child(2) {
            height: 20px;
          }
          img:nth-child(3) {
            height: 25px;
          }
          img:nth-child(4) {
            height: 35px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 954px) {
  .container_customers {
    .row {
      flex-direction: column;
      .cart {
        padding: 45px 35px 10px 20px;
        width: 100%;
        .cart_header {
          padding-bottom: 20px;
          .cart_title {
            font-size: 16px;

            img {
              height: 50px;
              margin-top: 4px;
              padding-left: 3%;
            }
          }

          .cart_header_logo {
            display: none;
          }
        }

        .cart_body {
          line-height: 24px;
        }

        .cart_footer {
          text-align: center;
          .first_btn {
            font-size: 14px;
            padding: 16px 50px 16px 16px;
          }

          .second_btn {
            font-size: 14px;
            margin-left: -45px;
            padding: 16px 22px;
          }
        }
      }

      .cart:before {
        border-right: none;
        border-bottom: none;
      }

      .cart_insurance {
        padding: 40px 20px 50px 20px;
        .img_grapper {
          img {
            height: 42px;
          }
        }
      }
    }

    .coverage_background {
      flex-direction: column-reverse;
      .cart {
        .cart_title {
          font-size: 16px;

          .title_bg {
            margin-top: -12px;
            height: 50px;
            padding-left: 2%;
          }
        }
        font-size: 16px;

        .img_small {
          img {
            height: 45px;
          }
          img:nth-child(4) {
            height: 55px;
          }
        }
        .img_medium {
          img {
            height: 80px;
          }
        }
        .img_big {
          img {
            height: 85px;
          }
          img:nth-child(2) {
            height: 40px;
          }
          img:nth-child(3) {
            height: 45px;
          }
          img:nth-child(4) {
            height: 55px;
          }
        }
      }

      .cart:nth-child(1) {
        padding-bottom: 50px;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .container_customers {
    .coverage_background {
      .cart {
        .img_small {
          img {
            height: 35px;
          }
          img:nth-child(4) {
            height: 45px;
          }
        }
        .img_medium {
          img {
            height: 70px;
          }
        }
        .img_big {
          img {
            height: 75px;
          }
          img:nth-child(2) {
            height: 30px;
          }
          img:nth-child(3) {
            height: 35px;
          }
          img:nth-child(4) {
            height: 45px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .container_customers {
    .coverage_background {
      background-image: url("../../assets/images/coverage_movil_bg.png");
      .cart {
        .img_small {
          img {
            height: 30px;
          }
          img:nth-child(4) {
            height: 35px;
          }
        }
        .img_medium {
          img {
            height: 60px;
          }
        }
        .img_big {
          img {
            height: 70px;
          }
          img:nth-child(2) {
            height: 25px;
          }
          img:nth-child(3) {
            height: 30px;
          }
          img:nth-child(4) {
            height: 35px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 520px) {
  .container_customers {
    .coverage_background {
      background-image: url("../../assets/images/coverage_movil_bg.png");
      .cart {
        .img_small {
          img {
            height: 20px;
          }
          img:nth-child(4) {
            height: 25px;
          }
        }
        .img_medium {
          img {
            height: 50px;
          }
        }
        .img_big {
          img {
            height: 60px;
          }
          img:nth-child(2) {
            height: 15px;
          }
          img:nth-child(3) {
            height: 20px;
          }
          img:nth-child(4) {
            height: 25px;
          }
        }
      }
    }
  }
}
