@font-face {
  font-family: "Poppins";
  src: url("../../../public/fonts/POPPINS.ttf");
}

.container_tools {
  .ytp-large-play-button {
    height: 10px !important;
  }
  span {
    font-family: "Poppins";
  }
  .header_container {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    gap: 64px;

    .header_title {
      color: #fff;
      display: flex;
      font-size: 45px;
      line-height: 56.03px;
      padding: 40px;

      .title_bold {
        font-weight: 700;
      }

      img {
        height: 93px;
        margin-top: -18px;
        padding-left: 50px;
        position: absolute;
      }
    }

    .header_subtile {
      border: 1px solid #ffffff42;
      border-radius: 27px 0 0 27px;
      box-shadow: 0px -7px 11px 0px #a48fff1f inset;
      color: #fff;
      display: flex;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      margin: 40px 0 40px 60px;
      place-items: center;
      text-align: right;
      width: 550px;
      span {
        padding: 4px 20px 4px 20px;
      }
    }
  }

  .body_container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .video_row {
    display: flex;
    width: 100%;
  }

  .video1 {
    // background-image: url("../../assets/images/video1bg.png");
    // background-size: cover;
    // background-position: center;
    //height: 400px;
    position: relative;
    width: 50%;
  }

  .video2 {
    // background-image: url("../../assets/images/video2bg.png");
    // background-size: cover;
    // background-position: center;
    // height: 400px;
    position: relative;
    width: 50%;
  }
  .video3 {
    // background-image: url("../../assets/images/video3bg.png");
    // background-size: cover;
    // background-position: center;
    // height: 400px;
    position: relative;
    width: 50%;
  }
  .video4 {
   /*  background-image: url("../../assets/images/video4bg.png");
    background-size: cover;
    background-position: center;
    height: 400px; */
    position: relative;
    width: 50%;
  }

  .video_description_container {
    background: linear-gradient(
      90deg,
      rgba(2, 28, 62, 0.58) 0%,
      rgba(23, 48, 89, 0.5104) 100%
    );
    color: #fff;
    height: 112px;
    margin-top: -112px;
    padding: 20px 0px 20px 40px;
    position: absolute;
    bottom: 0;
  }

  .video_description {
    display: flex;
    flex-direction: column;
    width: 85%;
    gap: 20px;

    span {
      font-family: Poppins;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
    }

    span:first-child {
      font-size: 30px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: -0.36000001430511475px;
      text-align: left;
    }
  }

  @media only screen and (max-width: 1200px) {
    .header_container {
      .header_title {
        font-size: 36px;

        img {
          margin-top: -12px;
          height: 80px;
        }
      }
      .header_subtile {
        margin: 40px 0 40px 36px;
      }
    }
    /* .video1 {
      height: 280px;
    }
    .video2 {
      height: 280px;
    }
    .video3 {
      height: 280px;
    }
    .video4 {
      height: 280px;
    } */
  }

  @media only screen and (max-width: 1100px) {
    .header_container {
      .header_subtile {
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .header_container {
      .header_title {
        font-size: 30px;

        img {
          margin-top: -6px;
          padding-left: 40px;

        }
      }
      .header_subtile {
        font-size: 14px;
        margin: 40px 0 40px 20px;
      }
    }

    .video_description_container {
      padding: 14px 0 0 14px;

      .video_description {
        gap: 8px;
        width: 94%;
        span:first-child {
          font-size: 20px;
        }
      }
    }

    /* .video1 {
      height: 240px;
    }
    .video2 {
      height: 240px;
    }
    .video3 {
      height: 240px;
    }
    .video4 {
      height: 240px;
    } */
  }

  @media only screen and (max-width: 992px) {
    .header_container {
      gap: 20px;
      .header_title {
        img {
          height: 70px;
          margin-top: -5px;
          padding-left: 40px;

        }
      }
      .header_subtile {
        font-size: 13px;
        margin: 40px 0 40px 10px;
      }
    }

    .video_description {
      span {
        line-height: normal;
      }
    }

    /* .video1 {
      height: 260px;
    }
    .video2 {
      height: 260px;
    }
    .video3 {
      height: 260px;
    }
    .video4 {
      height: 260px;
    } */
  }

  @media only screen and (max-width: 910px) {
    .header_container {
      .header_title {
        font-size: 22px;

        img {
          margin-top: 1px;
          padding-left: 22px;

        }
      }
      .header_subtile {
        font-size: 12px;
      }
    }
  }

  @media only screen and (max-width: 890px) {
    .header_container {
      flex-direction: column-reverse;
      gap: 0px;
      padding: 27px 10px;
      .header_title {
        font-size: 15px;
        padding: 10px;
        img {
          height: 50px;
          margin-top: 3px;
          padding-left: 15px;
        }
      }
      .header_subtile {
        font-size: 12px;
        line-height: normal;
        padding: 8px 14px;
        display: flex;
        align-self: self-end;
        width: 87%;
        margin: 40px -10px 40px 10px;

        span {
          padding: 10px;
        }
      }
    }

    .video_row {
      flex-direction: column;
    }

    .video1 {
      // height: 280px;
      width: 100%;
    }
    .video2 {
      // height: 280px;
      width: 100%;
    }
    .video3 {
      // height: 280px;
      width: 100%;
    }
    .video4 {
      // height: 280px;
      width: 100%;
    }

    .video_description {
      span {
        line-height: normal;
      }
    }

    .video_description_container {
      height: 40%;
      padding: 18px 0 0 20px;
    }
  }
}
