@font-face {
  font-family: "Poppins";
  src: url("../../../public/fonts/POPPINS.ttf");
}

.container_footer {
  .menu_container {
    display: flex;
    flex-direction: row;
    padding: 34px 0px;
    position: relative;
    .menu {
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      gap: 18px;
      padding-left: 40px;
      width: 18%;

      span {
        color: rgba(239, 237, 253, 0.6);
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;
      }

      .title {
        color: #f4f0ff;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        cursor: auto;
      }
    }

    .last_menu {
      width: 30%;

      .footer_logo {
        img {
          height: 430px;
          position: absolute;
          bottom: 0px;
          right: 0px;
          content:url("../../assets/images/footer_logo3.png");
        }
      }
    }
  }
  .mobil_suscribe {
    display: none;
  }
}

@media only screen and (max-width: 1300px) {
  .container_footer {
    .menu_container {
      .last_menu {
        .footer_logo {
          img {
            height: 350px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .container_footer {
    .menu_container {
      .last_menu {
        .footer_logo {
          img {
            height: 300px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .container_footer {
    .menu_container {
      .last_menu {
        .footer_logo {
          img {
            height: 250px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .container_footer {
    .menu_container {
      padding: 20px 10px;
      .menu {
        gap: 4px;
        padding-left: 10px;
        .title {
          font-size: 13px;
        }

        span {
          font-size: 11px;
        }
      }
    }
  }
}

@media only screen and (max-width: 890px) {
  .container_footer {
    .menu_container {
      padding: 20px 5px;

      .menu {
        gap: 4px;
        padding-left: 5px;
        .title {
          font-size: 14px;
        }

        span {
          font-size: 12px;
        }
      }

      .last_menu {
        .footer_logo {
          img {
            height: 222px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 730px) {
  .container_footer {
    .menu_container {
      .last_menu {
        .footer_logo {
          img {
            height: 182px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .container_footer {
    .menu_container {
      flex-direction: column;
      padding: 20px 5px 0px 5px;
      gap: 15px;
      .menu {
        gap: 4px;
        padding-left: 30px;
        width: 100%;
        .title {
          font-size: 14px;
        }

        span {
          font-size: 14px;
        }
      }
      .last_menu {
        width: 100%;
        .footer_logo {
          display: flex;
          justify-content: right;
          img {
            content:url("../../assets/images/footer_logo4.png");
            height: auto;
            position: relative;
            width: 100%;
          }
        }
      }
    }
  }
}
