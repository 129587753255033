@font-face {
  font-family: "Poppins";
  src: url("../../../public/fonts/POPPINS.ttf");
}

.container_coverage {
  color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;

  .title_mobile {
    display: none;
  }

  .wrapper {
    align-self: flex-end;
    padding: 140px 60px 100px 0px;
    position: absolute;
    box-sizing: border-box;
    width: 40%;

    .title {
      text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.4);
      font-size: 45px;
      font-style: normal;
      font-weight: 700;
      line-height: 56.025px;
      padding-bottom: 38px;
      text-align: center;

      span {
        font-family: "Poppins";
        position: relative;
      }

      span:nth-child(3) {
        font-weight: 400;
      }

      img {
        height: 93px;
        margin-top: -16px;
        padding-left: 3%;
        position: absolute;
      }
    }
  }

  .cart {
    align-items: flex-start;
    background: linear-gradient(
        180deg,
        rgba(169, 163, 194, 0.12) 0%,
        rgba(169, 163, 194, 0.12) 100%
      ),
      rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    display: flex;
    filter: drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.4));
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
    padding: 15px;

    .cart_title {
      color: #f4f0ff;
      text-align: center;
      font-family: "Poppins";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    .cart_body {
      color: #fff;
      text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.4);
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.12px;
    }
  }

  .map {
    height: auto;
    position: relative;
    width: 100%;
  }

  .short_map {
    display: none;
  }
}

@media only screen and (max-width: 1350px) {
  .container_coverage {
    .wrapper {
      padding: 50px 30px 100px 0px;
      width: 44%;
    }
  }
}

@media only screen and (max-width: 1265px) {
  .container_coverage {
    .wrapper {
      padding: 28px 30px 100px 0px;
      width: 48%;

      .title {
        img {
          height: 70px;
          margin-top: -2px;
          padding-left: 5%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1112px) {
  .container_coverage {
    .wrapper {
      padding: 20px 20px 0px 30px;
      width: 50%;
      .title {
        padding-bottom: 20px;
      }

      .cart {
        margin-bottom: 18px;
        gap: 6px;
      }
    }
  }
}

@media only screen and (max-width: 1020px) {
  .container_coverage {
    .wrapper {
      width: 54%;

      .title {
        line-height: normal;
        padding-bottom: 28px;
        img {
          height: 70px;
          margin-top: 4px;
          padding-left: 5%;
        }
      }

      .cart {
        gap: 4px;
        margin-bottom: 16px;
        padding: 8px;
      }
    }
  }
}

@media only screen and (max-width: 970px) {
  .container_coverage {
    .wrapper {
      height: 564px;
    }
  }
}

@media only screen and (max-width: 910px) {
  .container_coverage {
    .wrapper {
      height: 532px;
    }
  }
}

@media only screen and (max-width: 890px) {
  .container_coverage {
    .map {
      display: none;
    }

    .short_map {
      display: block;
    }

    .title_mobile {
      display: block;
      text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.4);
      font-size: 45px;
      font-style: normal;
      font-weight: 700;
      line-height: 56.025px;
      padding: 30px 0 0 24px;
      position: absolute;
      text-align: left;

      span {
        font-family: "Poppins";
        position: relative;
      }

      span:nth-child(3) {
        font-weight: 400;
      }

      img {
        height: 55px;
        margin-top: 8px;
        padding-left: 3%;
        position: absolute;
      }
    }
    .wrapper {
      background-image: url("../../assets/images/movilMap.png");
      background-size: cover;
      padding: 25px 20px;
      width: 100%;
      height: auto;
      position: relative;

      .title {
        display: none;
      }

      .cart {
        gap: 10px;
        padding: 20px;
        .cart_title {
          font-size: 14px;
        }
      }
    }
  }
}
